import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Link} from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Seite nicht gefunden" />
      <div className="mt-24">
          <h2 className="text-3xl md:text-4xl font-bold uppercase mb-4 text-white">Seite nicht gefunden</h2>
          <p className="text-lg text-gray md:text-2xl">Sie haben gerade eine Seite aufgerufen, die nicht existiert.<br />
              <Link className="text-white hover:text-opacity-70 transition duration-300" to="/">Zurück zur Startseite,</Link>
          </p>
      </div>
  </Layout>
)

export default NotFoundPage
